import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEffect } from 'react';
import { useCallback } from 'react';

const Campaigns = () => {
  const navigate = useNavigate();
  
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const loadCampaigns = useCallback(async () => {
    try {
      const response = await api.get(`/campaigns?page=${page + 1}&limit=${rowsPerPage}`);

      setRows(response.data.campaigns.data);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }, [page, rowsPerPage])

  const deleteCampaign = async (id) => {
    try {
      if (!window.confirm('Tem certeza que deseja remover essa campanha?')) return;

      await api.delete(`/campaigns/${id}`);

      toast.success('Campanha deletada com sucesso!');

      loadCampaigns();
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    <MainCard title="Campanhas" secondary={
      <button onClick={() => { window.open('https://google.com', '_blank') }} style={{ cursor: 'pointer', background: 'none', border: 'none' }
    }><HelpIcon sx={{ color: '#B39DDB' }} /></button>}>
      <div style={{marginBottom: '20px'}}>
        <Button variant="contained" style={{backgroundColor: '#B39DDB'}} onClick={() => {
          navigate('/campaigns/new')
        }}>
          Cadastrar
        </Button>
      </div>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align='right'>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align='right'>
                      <Tooltip title="Funis" placement="top-start" onClick={() => {
                        navigate(`/funnels/${row.id}`)
                      }}>
                        <FilterAltIcon sx={{ cursor: 'pointer' }} />
                      </Tooltip>
                      <Tooltip title="Editar" placement="top-start" onClick={() => {
                        navigate(`/campaigns/${row.id}`)
                      }}>
                        <EditIcon sx={{ cursor: 'pointer' }} />
                      </Tooltip>
                      <Tooltip title="Deletar" placement="top-start" onClick={() => {
                        deleteCampaign(row.id)
                      }}>
                        <DeleteIcon sx={{ marginLeft: '5px', cursor: 'pointer' }}  />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        >

        </TablePagination>
      </Paper>
    </MainCard>
  )
};

export default Campaigns