import { Handle, Position, useReactFlow } from 'reactflow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Delete from '@mui/icons-material/Delete'

export const PageNode = ({ id, isConnectable }) => {
  const {getNodes, setNodes, setEdges } = useReactFlow();
  const [isOpen, setIsOpen] = useState(false);
  const [pageName, setPageName] = useState('');
  const [pageDestUrl, setPageDestUrl] = useState('')
  const [comment, setComment] = useState('')

  useEffect(() => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    if (curNode.pageName) {
      setPageName(curNode.pageName);
    }

    if (curNode.pageDestUrl) {
      setPageDestUrl(curNode.pageDestUrl)
    }

    if (curNode.comment) {
      setComment(curNode.comment)
    }
  }, [id, getNodes]);

  const onSave = () => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    curNode.pageName = pageName;
    curNode.pageDestUrl = pageDestUrl;
    curNode.comment = comment;

    const pos = nodes
      .map(function (e) {
        return e.id;
      })
      .indexOf(curNode.id);

    nodes[pos] = curNode;

    setNodes(nodes);

    toast.success('Sucesso, agora salve cliquando no disquete.');
  }

  const deleteNode = () => {    
    setEdges((edges) => {
      return edges.filter((edge) => {
        return edge.target !== id && edge.source !== id
      })
    })

    setNodes((nds) => nds.filter((node) => node.id !== id))
  }

  return (
    <div style={{backgroundColor: '#339393', padding: '10px', width: '9vw', display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: '#fff', fontSize: '12px'}}>Página</span>
        {isOpen ? 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowUpIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div> : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowDownIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <span style={{color: '#fff', marginTop: '10px'}}>Nome da Página</span>
          <input id="name" name="name" className="nodrag" value={pageName} onChange={(ev) => setPageName(ev.target.value)} />
          <span style={{color: '#fff', marginTop: '5px'}}>URL de destino</span>
          <input id="desturl" name="desturl" className="nodrag" value={pageDestUrl} onChange={(ev) => setPageDestUrl(ev.target.value)} />
          <span style={{color: '#fff', marginTop: '5px'}}>Comentário</span>
          <textarea id="comment" name="comment" className="nodrag" value={comment} onChange={(ev) => setComment(ev.target.value)} />
          <button style={{marginTop: '10px'}} onClick={() => onSave()}>Salvar</button>
        </>
      )}
      
      <Handle 
        type="target"
        position={Position.Left}
        id={uuidv4()}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
        isConnectable={isConnectable}
      />
    </div>
  );
};