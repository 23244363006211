import { Breadcrumbs, Button, Link, Select, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../services/api';

import MainCard from '../../ui-component/cards/MainCard';

function Offer() {
  const navigate = useNavigate()
  const params = useParams();

  const [id, setId] = useState(0);
  const [name, setName] = useState('')
  const [checkoutLink, setCheckoutLink] = useState('')
  const [comissionAmount, setComissionAmount] = useState('')
  const [affiliateNetwork, setAffiliateNetwork] = useState(-1)

  const submit = async () => {
    try {
      if (params.id) {
        await api.put(`/offers/${params.id}`, { name, checkoutLink, comissionAmount, affiliateNetwork: +affiliateNetwork })
        
        toast.success('Oferta editada com sucesso!')
      } else {
        await api.post('/offers', { name, checkoutLink, comissionAmount, affiliateNetwork: +affiliateNetwork })

        toast.success('Oferta cadastrada com sucesso!')
      }
      navigate('/offers')
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }

  const loadOffer = useCallback(async () => {
    try {
      const response = await api.get(`/offers/${params.id}`)

      setName(response.data.offer.name);
      setCheckoutLink(response.data.offer.checkout_link);
      setComissionAmount(response.data.offer.comission_amount);
      setAffiliateNetwork(response.data.offer.affiliate_network);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }, [params]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);

      loadOffer()
    }
  }, [params, loadOffer]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '10px'}}>
        <Link underline="hover" color="inherit" style={{cursor: 'pointer'}} onClick={() => {
          navigate('/offers')
        }}>
          Ofertas
        </Link>
        <Typography color="text.primary">{id === 0 ? 'Cadastrar' : 'Editar'} oferta</Typography>
      </Breadcrumbs>
      <MainCard title={`${id === 0 ? 'Cadastrar' : 'Editar'} oferta`}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TextField label="Nome" variant="outlined" value={name} onChange={(ev) => setName(ev.target.value)} />

          <TextField label="Link do Checkout" variant="outlined" value={checkoutLink} onChange={(ev) => setCheckoutLink(ev.target.value)} sx={{marginTop: '20px'}} />

          <TextField type='number' label="Valor padrão comissão" variant="outlined" value={comissionAmount} onChange={(ev) => setComissionAmount(ev.target.value)} sx={{marginTop: '20px'}} />

          <Select
            sx={{marginTop: '20px'}}
            native
            value={affiliateNetwork}
            onChange={(ev) => {
              setAffiliateNetwork(ev.target.value)
            }}
          >
            <option value={-1} disabled>
              Escolha a Rede de Afiliado
            </option>
            {/* <option value={0}>Abmex</option>
            <option value={1}>AppMax</option>
            <option value={2}>Braip</option>
            <option value={3}>Checkout Plus</option>
            <option value={4}>CloudFox</option>
            <option value={5}>Doppus</option>
            <option value={6}>Eduzz</option>
            <option value={7}>Evermart</option>
            <option value={8}>Evipes</option> */}
            <option value={9}>Greenn</option>
            {/* <option value={10}>Digital Manager Guru</option>
            <option value={11}>Hotmart</option>
            <option value={12}>Hubsale</option>
            <option value={13}>Kiwify</option>
            <option value={14}>Monetizze</option>
            <option value={15}>Payt</option>
            <option value={16}>PerfectPay</option>
            <option value={17}>Smart Checkout</option>
            <option value={18}>ThriveChart</option>
            <option value={19}>Ticto</option>
            <option value={20}>Yampi</option>
            <option value={21}>Upnid</option>
            <option value={22}>C2 Cpa</option>
            <option value={23}>Outros</option> */}
          </Select>
    
            <Button variant="contained" style={{backgroundColor: '#B39DDB', marginTop: '20px'}} onClick={() => submit()}>
              {id === 0 ? 'Cadastrar' : 'Editar'}
            </Button>
        </div>
      </MainCard>
    </>
  )
}

export default Offer;
