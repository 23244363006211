import React, { useState } from 'react';
import ReactFlow, { BaseEdge, EdgeLabelRenderer, addEdge, getBezierPath, useEdgesState, useNodesState } from 'reactflow';

import { PageNode } from './nodes/PageNode';
import { TrafficNode } from './nodes/TrafficNode';
import { OfferNode } from './nodes/OfferNode';
import { RotationNode } from './nodes/RotationNode';
import { IfNode } from './nodes/IfNode';
import { DistributionNode } from './nodes/DistributionNode';
import { ScriptNode } from './nodes/ScriptNode';
import MainCard from 'ui-component/cards/MainCard';
import HelpIcon from '@mui/icons-material/Help';
import { Breadcrumbs, Button, TextField, Typography, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useEffect } from 'react';

import { Menu, Item, useContextMenu, Separator } from 'react-contexify';
import { useRef } from 'react';
import { memo } from 'react';
import { useMemo } from 'react';
import { IconCopy, IconX } from '@tabler/icons';
import moment from 'moment'

import { v4 as uuidv4 } from 'uuid';
import 'react-contexify/ReactContexify.css';
import 'reactflow/dist/style.css';

const getId = () => `dndnode_${Math.floor(Math.random() * 100)}`;

const MENU_ID = 'hubmaze_menu';

const Funnel = () => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [edgeColor, setEdgeColor] = useState('#A4A4A4')

  const navigate = useNavigate()
  const params = useParams();

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const [id, setId] = useState(0);
  const [name, setName] = useState('');

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));

  const handleContextMenu = (event) => {
    show({
      event,
      props: {
        key: 'value'
      }
    })
  }

  const handleItemClick = ({ id, event, props }) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const newNodeId = getId();

    const newNode = {
      id: newNodeId,
      position
    };

    switch (id) {
      case "page":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'pageNode',
          pageName: '',
          pageDestUrl: '',
          comment: '',
        }));
        break;
      case "traffic":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'trafficNode',
          trafficSourceId: 0,
          trafficSourceDescription: '',
          comment: '',
        }));
        break;
      case "offer":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'offerNode',
          offerId: 0,
          comment: '',
        }));
        break;
      case "rotation":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'rotationNode'
        }));
        break;
      case "condition":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'ifNode'
        }));
        break;
      case "script":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'scriptNode'
        }));
        break;
      case "distribution":
        setNodes(nds => nds.concat({
          ...newNode,
          type: 'distributionNode'
        }));
        break;
      default:
        console.log('UNHANDLED OPTION')
    }
  }

  const nodeTypes = useMemo(() => ({
    pageNode: PageNode,
    trafficNode: TrafficNode,
    offerNode: OfferNode,
    rotationNode: RotationNode,
    ifNode: IfNode,
    distributionNode: DistributionNode,
    scriptNode: ScriptNode
  }), []);

  const submit = async () => {
    try {
      let curFlow = '';
      let curNodes = []
      let curEdges = []

      if (reactFlowInstance) {
        const flow = reactFlowInstance.toObject();

        if (flow.nodes.length === 0) {
          return toast.error('Você precisa adicionar algum elemento');
        }

        const nodes = flow.nodes
        const edges = flow.edges

        for (let node of nodes) {
          if (node.type === 'trafficNode') {
            curNodes.push({
              nodeId: node.id,
              type: 1,
              trafficSourceId: node.trafficSourceId,
              trafficSourceDescription: node.trafficSourceDescription,
              comment: node.comment
            })
          }

          if (node.type === 'pageNode') {
            curNodes.push({
              nodeId: node.id,
              type: 0,
              pageName: node.pageName,
              pageDestUrl: node.pageDestUrl,
              comment: node.comment
            })
          }

          if (node.type === 'offerNode') {
            curNodes.push({
              nodeId: node.id,
              type: 2,
              offerId: node.offerId,
              comment: node.comment
            })
          }
        }

        for (let edge of edges) {
          curEdges.push({
            edgeId: edge.id,
            source: edge.source,
            sourceHandle: edge.sourceHandle,
            target: edge.target,
            targetHandle: edge.targetHandle,
          })
        }

        curFlow = JSON.stringify(flow);
      }

      if (params.id) {
        await api.put(`/funnels/${params.id}`, { name, instance: curFlow })

        toast.success('Funil editado com sucesso!')
      } else {
        await api.post(`/funnels/${params.campaignId}`, { name, instance: curFlow })

        toast.success('Funil cadastrado com sucesso!')
      }
      navigate(`/funnels/${params.campaignId}`)
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }

  // const countTimestampsInRange = (timestamps, startTimestamp, endTimestamp) => {
  //   let count = 0;

  //   timestamps.forEach(timestamp => {
  //     if (timestamp >= startTimestamp && timestamp <= endTimestamp) {
  //       count++;
  //     }
  //   });

  //   return count;
  // }

  // const filterEdges = useCallback(() => {
  //   if (startDate === null || endDate === null) {
  //     return
  //   }

  //   const startTimestamp = new Date(startDate).getTime() / 1000;
  //   const endTimestamp = new Date(endDate).getTime() / 1000;

  //   const newCountEdges = edges.map((obj) => {
  //     const count = countTimestampsInRange(obj.countByDate, startTimestamp, endTimestamp);
  //     obj.count = count;
  //     return obj
  //   });
  
  //   setEdges(newCountEdges);
  // }, [edges, endDate, setEdges, startDate])

  const loadFunnel = useCallback(async () => {
    try {
      const response = await api.get(`/funnels/${params.id}?startDate=${startDate}&endDate=${endDate}`)

      if (!response.data.funnel) {
        toast.error('Ops, ocorreu algum erro!');
        return;
      }

      const curFlow = JSON.parse(response.data.funnel.instance);

      if (curFlow) {
        setNodes(curFlow.nodes || []);
        setEdges(curFlow.edges || []);
      }

      setName(response.data.funnel.name);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }, [params, setEdges, setNodes, startDate, endDate]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);

      loadFunnel()
    }
  }, [params, loadFunnel]);

  // useEffect(() => {
  //   filterEdges()
  // }, [startDate, endDate, filterEdges])

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    const filteredEdges = edges.filter(edge => edge.id !== id);
    setEdges(filteredEdges);
  };

  const ButtonEdge = memo(({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  }) => {
    if (edges.length === 0 || !edges.find((edge) => edge.id === id)) {
      return
    }

    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    const edge = edges.filter(edge => edge.id === id)

    const offerNode = nodes.filter(node => node.type === 'offerNode')

    const edgeIndex = edges.findIndex(edge => edge.id === id);

    const node = nodes.filter(node => node.id === edge[0].source)

    let customStyle = {}

    if (offerNode && offerNode[0]) {
      if (offerNode[0].edgesToColor) {
        const containsMyId = (offerNode[0].edgesToColor.indexOf(edge[0].id) > -1);

        if (containsMyId) {
          customStyle = {
            stroke: edgeColor
          }
        }
      }
    }

    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={customStyle} />
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            <span style={{ color: 'white', marginRight: '10px', background: '#808080', padding: '5px', borderRadius: '10px' }}>{edge[0].count || 0}</span>
            <button className="edgebutton" style={{ border: 'none', borderRadius: '10px', padding: '5px' }} onClick={(event) => onEdgeClick(event, id)}>
              <IconX stroke={0.7} size="0.7rem" />
            </button>
            <button className="edgebutton" style={{ border: 'none', borderRadius: '10px', padding: '5px', marginLeft: '5px' }} onClick={(event) => {
              if (edge[0]) {
                const fluxId = edge[0].fluxId

                const url = process.env.NODE_ENV === 'development' ? `http://localhost:3333/tracking/${params.id}/${fluxId}` : `https://api.hubmaze.com/tracking/${params.id}/${fluxId}`

                navigator.clipboard.writeText(url)

                toast.success('Copiado!')
              }
            }}>
              <IconCopy stroke={0.7} size="0.7rem" />
            </button>
            {node[0].type === 'rotationNode' && <>
            <input placeholder='%' value={edge[0].percentage || ''} onChange={(event) => {
              const newEdges = [...edges];
              newEdges[edgeIndex] = { ...newEdges[edgeIndex], percentage: event.target.value };
              setEdges(newEdges);
            }} style={{marginLeft: '5px', width: '50px', borderRadius: '10px', border: 'none', padding: '5px', fontSize: '12px'}} />
            <span style={{ color: '#FFF', marginLeft: '3px' }}>%</span>
            </>}
          </div>
        </EdgeLabelRenderer>
      </>
    );
  });

  const edgeTypes = {
    buttonedge: ButtonEdge,
  };

  const onConnect = useCallback(params => {
    params.type = 'buttonedge';
    params.fluxId = uuidv4();
    params.count = 0;
    params.countByDate = [];
    setEdges(eds => addEdge(params, eds));
  }, [setEdges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onEdgeClickFlow = useCallback(async (_, edge) => {
    try {
      const response = await api.get(`/funnels/edge/${params.id}/${edge.fluxId}?startDate=${startDate}&endDate=${endDate}`)

      if (!response.data.funnel) {
        toast.error('Ops, ocorreu algum erro!');
        return;
      }

      const curFlow = JSON.parse(response.data.funnel.instance);

      if (curFlow) {
        setNodes(curFlow.nodes || []);
        setEdges(curFlow.edges || []);
      }

      setName(response.data.funnel.name);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }, [params, setEdges, setNodes, startDate, endDate])

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      if (!event.dataTransfer.getData('application/reactflow')) {
        return;
      }

      const data = JSON.parse(
        event.dataTransfer.getData('application/reactflow'),
      );
      const type = data.nodeType;

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNodeId = getId();

      let newNode

      if (type === 'pageNode') {
        newNode = {
          id: newNodeId,
          type,
          position,
          pageName: '',
          pageDestUrl: '',
          comment: '',
        }
      } else if (type === 'offerNode') {
        newNode = {
          id: newNodeId,
          type,
          position,
          offerId: 0,
          comment: '',
        }
      } else if (type === 'trafficNode') {
        newNode = {
          id: newNodeId,
          type,
          position,
          trafficSourceId: 0,
          trafficSourceDescription: '',
          comment: '',
        }
      } else {
        return
      }

      setNodes(nds => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes],
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
        <Link underline="hover" color="inherit" style={{ cursor: 'pointer' }} onClick={() => {
          navigate('/campaigns')
        }}>
          Campanhas
        </Link>
        <Typography color="text.primary">{id === 0 ? 'Cadastrar' : 'Editar'} funil</Typography>
      </Breadcrumbs>
      <MainCard title="Campanha: Funil 1" secondary={
      <button onClick={() => { window.open('https://google.com', '_blank') }} style={{ cursor: 'pointer', background: 'none', border: 'none' }
    }><HelpIcon sx={{ color: '#B39DDB' }} /></button>}>
        <TextField type="text" value={name} variant="outlined" sx={{ width: '100%' }} onChange={(ev) => {
          setName(ev.target.value);
        }} />

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: '5px', fontWeight: '500', fontSize: '14px', fontFamily: 'Roboto, sans-serif' }}>Faixa de Data</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input 
                type='date'
                value={startDate || moment().format('yyyy-MM-DD')}
                style={{ borderRadius: '10px', border: '1px solid #bababa', padding: '10px' }}
                onChange={(ev) => {
                  setStartDate(ev.target.value)
                }}
              />
              <input 
                type='date'
                value={endDate || moment().format('yyyy-MM-DD')}
                style={{ marginLeft: '10px', borderRadius: '10px', border: '1px solid #bababa', padding: '10px' }}
                onChange={(ev) => {
                  setEndDate(ev.target.value)
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ width: '100%', height: '40vh', marginTop: '2vh' }} ref={reactFlowWrapper}>
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            onConnect={onConnect}
            onEdgeClick={onEdgeClickFlow}
            fitView
            onInit={setReactFlowInstance}
            onContextMenu={handleContextMenu}
            onDragOver={onDragOver}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDrop={onDrop}
          />
          <Menu id={MENU_ID}>
            <Item id="page" onClick={handleItemClick}>Página</Item>
            <Item id="traffic" onClick={handleItemClick}>Fonte de tráfego</Item>
            <Item id="offer" onClick={handleItemClick}>Oferta</Item>
            <Separator />
            <Item id="rotation" onClick={handleItemClick}>Rotação</Item>
            {/* <Item id="condition" onClick={handleItemClick}>Condição</Item> */}
            {/* <Item id="script" onClick={handleItemClick}>Script</Item> */}
            {/* <Item id="distribution" onClick={handleItemClick}>Distribuição</Item> */}
          </Menu>
        </div>

        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
          <Button variant="contained" style={{ backgroundColor: '#409F51', width: '5vw' }} onClick={() => {
            submit()
          }}>
            Salvar
          </Button>
        </div>
      </MainCard>
    </>
  );
}

export default Funnel