import { Paper} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';


const Client = () => {
  return (
    <MainCard title="Plano Atual">
      <Paper>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '16px' }}>Starter</span>
            <span style={{ fontSize: '20px', marginTop: '10px', fontWeight: 'bold' }}>R$ 42,30 por mês</span>
            <span style={{ marginTop: '10px' }}>Seu plano será renovado em 18 de setembro de 2024</span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10vw' }}>
            <button style={{ backgroundColor: '#9E00FE', padding: '10px 40px', border: 'none', color: '#FFF', borderRadius: '5px', cursor: 'pointer' }}>Alterar Plano</button>
            <button style={{ backgroundColor: '#EEF2F6', padding: '10px 40px', border: 'none', color: '#959596', borderRadius: '5px', marginTop: '10px', cursor: 'pointer' }}>Cancelar Plano</button>
          </div>
        </div>
      </Paper>
    </MainCard>
  )
};

export default Client