import { Handle, Position, useReactFlow } from 'reactflow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import { useEffect } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';
import Delete from '@mui/icons-material/Delete'

export const OfferNode = ({ id, isConnectable }) => {
  const {getNodes, setNodes, setEdges } = useReactFlow();
  const [isOpen, setIsOpen] = useState(false);

  const [offers, setOffers] = useState([]);

  const [offerId, setOfferId] = useState(0)
  const [buyCount, setBuyCount] = useState(0)
  const [comment, setComment] = useState('')

  useEffect(() => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    if (curNode.offerId) {
      setOfferId(curNode.offerId);
    }

    if (curNode.comment) {
      setComment(curNode.comment)
    }

    if (curNode.buyCount) {
      setBuyCount(curNode.buyCount)
    }
  }, [id, getNodes]);

  const onSave = () => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    curNode.offerId = offerId;
    curNode.comment = comment;

    const pos = nodes
      .map(function (e) {
        return e.id;
      })
      .indexOf(curNode.id);

    nodes[pos] = curNode;

    setNodes(nodes);

    toast.success('Sucesso, agora salve cliquando no disquete.');
  }

  useEffect(() => {
    loadOffers()
  }, [])

  const loadOffers = async () => {
    try {
      const response = await api.get('/offers?page=1&limit=100');

      setOffers(response.data.offers.data);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }

  const deleteNode = () => {    
    setEdges((edges) => {
      return edges.filter((edge) => {
        return edge.target !== id && edge.source !== id
      })
    })

    setNodes((nds) => nds.filter((node) => node.id !== id))
  }

  return (
    <div style={{backgroundColor: '#FF7300', padding: '10px', width: '9vw', display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: '#fff', fontSize: '12px'}}>Oferta</span>
        <span style={{color: 'white', background: 'red', padding: '5px', borderRadius: '10px', position: 'absolute', top: -20, right: -5}}>{buyCount}</span>
        {isOpen ? 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowUpIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div> : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowDownIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <span style={{color: '#fff', marginTop: '10px'}}>Oferta</span>
          <select id="offer" name="offer" className="nodrag" value={offerId} onChange={(ev) => setOfferId(ev.target.value)}>
            <option value={0}>Selecione...</option>
            {offers.map((offer, i) => {
              return (
                <option key={i} value={offer.id}>{offer.name}</option>
              )
            })}
          </select>
          <span style={{color: '#fff', marginTop: '5px'}}>Comentário</span>
          <textarea
            id="comment"
            name="comment"
            className="nodrag"
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
          <button style={{marginTop: '10px'}} onClick={() => onSave()}>Salvar</button>
        </>
      )}
      
      <Handle
        type="target"
        position={Position.Left}
        id={uuidv4()}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
        isConnectable={isConnectable}
      />
    </div>
  );
};