// assets
import { IconChartBar, IconAd, IconCashBanknote } from '@tabler/icons';

// ==============================|| TRACKING MENU ITEMS ||============================== //

const tracking = {
  id: 'tracking',
  title: 'Tracking',
  type: 'group',
  children: [
    {
      id: 'campaigns',
      title: 'Campanhas',
      type: 'item',
      url: '/campaigns',
      icon: IconChartBar,
      breadcrumbs: false
    },
    {
      id: 'trafficsources',
      title: 'Fontes de Tráfego',
      type: 'item',
      url: '/trafficsources',
      icon: IconAd,
      breadcrumbs: false
    },
    {
      id: 'offers',
      title: 'Ofertas',
      type: 'item',
      url: '/offers',
      icon: IconCashBanknote,
      breadcrumbs: false
    }
  ]
};

export default tracking;
