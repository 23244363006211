import { Breadcrumbs, Button, Link, Select, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../services/api';

import MainCard from '../../ui-component/cards/MainCard';

function TrafficSource() {
  const navigate = useNavigate()
  const params = useParams();

  const [id, setId] = useState(0);
  const [name, setName] = useState('')
  const [trafficNetwork, setTrafficNetwork] = useState(-1)
  const [paidTraffic, setPaidTraffic] = useState(-1)

  const submit = async () => {
    try {
      if (params.id) {
        await api.put(`/traffic/sources/${params.id}`, { name, trafficNetwork: +trafficNetwork, paidTraffic: +paidTraffic === 1 ? true : false })
        
        toast.success('Fonte de Tráfego editada com sucesso!')
      } else {
        await api.post('/traffic/sources', { name, trafficNetwork: +trafficNetwork, paidTraffic: +paidTraffic === 1 ? true : false })

        toast.success('Fonte de Tráfego cadastrada com sucesso!')
      }
      navigate('/trafficsources')
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }

  const loadTrafficSource = useCallback(async () => {
    try {
      const response = await api.get(`/traffic/sources/${params.id}`)

      setName(response.data.trafficSource.name);
      setTrafficNetwork(+response.data.trafficSource.traffic_network);
      setPaidTraffic(response.data.trafficSource.paid_traffic ? 1 : 0);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }, [params]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);

      loadTrafficSource()
    }
  }, [params, loadTrafficSource]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '10px'}}>
        <Link underline="hover" color="inherit" style={{cursor: 'pointer'}} onClick={() => {
          navigate('/trafficsources')
        }}>
          Fontes de Tráfego
        </Link>
        <Typography color="text.primary">{id === 0 ? 'Cadastrar' : 'Editar'} Fonte de Tráfego</Typography>
      </Breadcrumbs>
      <MainCard title={`${id === 0 ? 'Cadastrar' : 'Editar'} Fonte de Tráfego`}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TextField label="Nome" variant="outlined" value={name} onChange={(ev) => setName(ev.target.value)} />

          <Select
            sx={{marginTop: '20px'}}
            native
            value={trafficNetwork}
            onChange={(ev) => {
              setTrafficNetwork(ev.target.value);
            }}
          >
            <option value={-1} disabled>
              Rede de Tráfego
            </option>
            <option value={0}>Google Ads</option>
            <option value={1}>Facebook Ads</option>
            <option value={2}>Taboola</option>
            <option value={3}>Outbrain</option>
          </Select>

          <Select
            sx={{marginTop: '20px'}}
            native
            value={paidTraffic}
            onChange={(ev) => {
              setPaidTraffic(ev.target.value);
            }}
          >
            <option value={-1} disabled>
              Tráfego Pago?
            </option>
            <option value={0}>Não</option>
            <option value={1}>Sim</option>
          </Select>
    
            <Button variant="contained" style={{backgroundColor: '#B39DDB', marginTop: '20px'}} onClick={() => submit()}>
              {id === 0 ? 'Cadastrar' : 'Editar'}
            </Button>
        </div>
      </MainCard>
    </>
  )
}

export default TrafficSource;
