import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { isAuthenticated } from 'services/auth';
import { Navigate } from 'react-router';
import Campaigns from 'views/campaigns';
import CreateCampaign from 'views/campaigns/Campaign';
import TrafficSources from 'views/trafficsources';
import TrafficSource from 'views/trafficsources/TrafficSource';
import Offers from 'views/offers';
import Offer from 'views/offers/Offer';
import Funnels from 'views/funnels';
import Funnel from 'views/funnels/Funnel';
import Client from 'views/client';
import ChangePassword from 'views/change-passwordd';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/New')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: isAuthenticated() ? <DashboardDefault /> : <Navigate to="/login" />
    },
    {
      path: 'dashboard',
      element: isAuthenticated() ? <DashboardDefault /> : <Navigate to="/login" />
    },
    {
      path: 'campaigns',
      element: isAuthenticated() ? <Campaigns /> : <Navigate to="/login" />
    },
    {
      path: 'campaigns/new',
      element: isAuthenticated() ? <CreateCampaign /> : <Navigate to="/login" />
    },
    {
      path: 'campaigns/:id',
      element: isAuthenticated() ? <CreateCampaign /> : <Navigate to="/login" />
    },
    {
      path: 'trafficsources',
      element: isAuthenticated() ? <TrafficSources /> : <Navigate to="/login" />
    },
    {
      path: 'trafficsources/new',
      element: isAuthenticated() ? <TrafficSource /> : <Navigate to="/login" />
    },
    {
      path: 'trafficsources/:id',
      element: isAuthenticated() ? <TrafficSource /> : <Navigate to="/login" />
    },
    {
      path: 'offers',
      element: isAuthenticated() ? <Offers /> : <Navigate to="/login" />
    },
    {
      path: 'offers/new',
      element: isAuthenticated() ? <Offer /> : <Navigate to="/login" />
    },
    {
      path: 'offers/:id',
      element: isAuthenticated() ? <Offer /> : <Navigate to="/login" />
    },
    {
      path: 'funnels/:id',
      element: isAuthenticated() ? <Funnels /> : <Navigate to="/login" />
    },
    {
      path: 'funnels/new/:campaignId',
      element: isAuthenticated() ? <Funnel /> : <Navigate to="/login" />
    },
    {
      path: 'funnels/edit/:id/:campaignId',
      element: isAuthenticated() ? <Funnel /> : <Navigate to="/login" />
    },
    {
      path: 'client',
      element: isAuthenticated() ? <Client /> : <Navigate to="/login" />
    },
    {
      path: 'changepassword',
      element: isAuthenticated() ? <ChangePassword /> : <Navigate to="/login" />
    },
  ]
};

export default MainRoutes;
