import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { isAuthenticated } from 'services/auth';
import { Navigate } from 'react-router';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Forgot = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Forgot')));
const Recover = Loadable(lazy(() => import('views/pages/authentication/auth-forms/RecoverPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: isAuthenticated() ? <Navigate to="/dashboard" /> : <AuthLogin3 />
    },
    {
      path: '/forgot',
      element: <Forgot />
    },
    {
      path: '/recover',
      element: <Recover />
    }
  ]
};

export default AuthenticationRoutes;
