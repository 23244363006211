import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEffect } from 'react';
import { useCallback } from 'react';

const affiliateNetwork = [
  'Abmex',
  'AppMax',
  'Braip',
  'Checkout Plus',
  'CloudFox',
  'Doppus',
  'Eduzz',
  'Evermart',
  'Evipes',
  'Greenn',
  'Digital Manager Guru',
  'Hotmart',
  'Hubsale',
  'Kiwify',
  'Monetizze',
  'Payt',
  'PerfectPay',
  'Smart Checkout',
  'ThriveChart',
  'Ticto',
  'Yampi',
  'Upnid',
  'C2 Cpa',
  'Outros'
]

const Offers = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const loadOffers = useCallback(async () => {
    try {
      const response = await api.get(`/offers?page=${page + 1}&limit=${rowsPerPage}`);

      setRows(response.data.offers.data);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }, [page, rowsPerPage])

  const deleteOffer = async (id) => {
    try {
      if (!window.confirm('Tem certeza que deseja remover essa oferta?')) return;

      await api.delete(`/offers/${id}`);

      toast.success('Oferta deletada com sucesso!');

      loadOffers();
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }

  useEffect(() => {
    loadOffers();
  }, [loadOffers]);

  return (
    <MainCard title="Ofertas" secondary={
      <button onClick={() => { window.open('https://google.com', '_blank') }} style={{ cursor: 'pointer', background: 'none', border: 'none' }
    }><HelpIcon sx={{ color: '#B39DDB' }} /></button>}>
      <div style={{ marginBottom: '20px' }}>
        <Button variant="contained" style={{ backgroundColor: '#B39DDB' }} onClick={() => {
          navigate('/offers/new')
        }}>
          Cadastrar
        </Button>
      </div>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Rede de Afiliado</TableCell>
                <TableCell>Valor padrão comissão</TableCell>
                <TableCell align='right'>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{affiliateNetwork[row.affiliate_network]}</TableCell>
                    <TableCell>R$ {row.comission_amount / 100}</TableCell>
                    <TableCell align='right'>
                      <Tooltip title="Editar" placement="top-start" onClick={() => {
                        navigate(`/offers/${row.id}`)
                      }}>
                        <EditIcon sx={{ cursor: 'pointer' }} />
                      </Tooltip>
                      <Tooltip title="Deletar" placement="top-start" onClick={() => {
                        deleteOffer(row.id)
                      }}>
                        <DeleteIcon sx={{ marginLeft: '5px', cursor: 'pointer' }} />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        >

        </TablePagination>
      </Paper>
    </MainCard>
  )
};

export default Offers