import { Breadcrumbs, Button, Link, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../services/api';

import MainCard from '../../ui-component/cards/MainCard';

function Campaign() {
  const navigate = useNavigate()
  const params = useParams();

  const [id, setId] = useState(0);
  const [name, setName] = useState('')

  const submit = async () => {
    try {
      if (params.id) {
        await api.put(`/campaigns/${params.id}`, { name })
        
        toast.success('Campanha editada com sucesso!')
      } else {
        await api.post('/campaigns', { name })

        toast.success('Campanha cadastrada com sucesso!')
      }
      navigate('/campaigns')
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }

  const loadCampaign = useCallback(async () => {
    try {
      const response = await api.get(`/campaigns/${params.id}`)

      setName(response.data.campaign.name);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!')
    }
  }, [params]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);

      loadCampaign()
    }
  }, [params, loadCampaign]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '10px'}}>
        <Link underline="hover" color="inherit" style={{cursor: 'pointer'}} onClick={() => {
          navigate('/campaigns')
        }}>
          Campanhas
        </Link>
        <Typography color="text.primary">{id === 0 ? 'Cadastrar' : 'Editar'} campanha</Typography>
      </Breadcrumbs>
      <MainCard title={`${id === 0 ? 'Cadastrar' : 'Editar'} campanha`}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TextField label="Nome" variant="outlined" value={name} onChange={(ev) => setName(ev.target.value)} />
    
            <Button variant="contained" style={{backgroundColor: '#B39DDB', marginTop: '20px'}} onClick={() => submit()}>
              {id === 0 ? 'Cadastrar' : 'Editar'}
            </Button>
        </div>
      </MainCard>
    </>
  )
}

export default Campaign;
