import { Handle, Position, useReactFlow } from 'reactflow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import Delete from '@mui/icons-material/Delete'

export const IfNode = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {setNodes, setEdges } = useReactFlow();

  const deleteNode = () => {    
    setEdges((edges) => {
      return edges.filter((edge) => {
        return edge.target !== id && edge.source !== id
      })
    })

    setNodes((nds) => nds.filter((node) => node.id !== id))
  }

  return (
    <div style={{backgroundColor: '#5F4563', padding: '10px', width: '9vw', display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: '#fff', fontSize: '12px'}}>SE...</span>
        {isOpen ? 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowUpIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div> : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowDownIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <span style={{color: '#fff', marginTop: '10px'}}>SE...</span>
          <select id="iff" name="iff" className="nodrag" />
          <span style={{color: '#fff', marginTop: '5px'}}>Operador</span>
          <select id="operator" name="operator" className="nodrag" />
          <span style={{color: '#fff', marginTop: '5px'}}>Valor</span>
          <input id="value" name="value" className="nodrag" />
          <span style={{color: '#fff', marginTop: '5px'}}>Comentário</span>
          <textarea id="comment" name="comment" className="nodrag" />
        </>
      )}
      
      <Handle
        type="target"
        position={Position.Left}
        id={uuidv4()}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
        style={{top: 10}}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
        style={{top: 35}}
      />
    </div>
  );
};