import { Handle, Position, useReactFlow } from 'reactflow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Delete from '@mui/icons-material/Delete'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import { useEffect } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';

export const TrafficNode = ({ id, isConnectable }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [traffics, setTraffics] = useState([]);
  const {getNodes, setNodes, setEdges} = useReactFlow();

  const [trafficSourceId, setTrafficSourceId] = useState(0);
  const [trafficSourceDescription, setTrafficSourceDescription] = useState('');
  const [comment, setComment] = useState('')

  useEffect(() => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    if (curNode.trafficSourceId) {
      setTrafficSourceId(curNode.trafficSourceId);
    }

    if (curNode.trafficSourceDescription) {
      setTrafficSourceDescription(curNode.trafficSourceDescription)
    }

    if (curNode.comment) {
      setComment(curNode.comment)
    }
  }, [id, getNodes]);

  const onSave = () => {
    const currentNodeFakeId = id;

    const nodes = getNodes();

    const curNode = nodes.filter(
      (node) => node.id === currentNodeFakeId,
    )[0];

    curNode.trafficSourceId = trafficSourceId;
    curNode.trafficSourceDescription = trafficSourceDescription;
    curNode.comment = comment;

    const pos = nodes
      .map(function (e) {
        return e.id;
      })
      .indexOf(curNode.id);

    nodes[pos] = curNode;

    setNodes(nodes);

    toast.success('Sucesso, agora salve cliquando no disquete.');
  }

  useEffect(() => {
    loadTraffics()
  }, [])

  const loadTraffics = async () => {
    try {
      const response = await api.get('/traffic/sources?page=1&limit=100');

      setTraffics(response.data.trafficSources.data);
    } catch (error) {
      toast.error('Ops, ocorreu algum erro!');
    }
  }

  const deleteNode = () => {    
    setEdges((edges) => {
      return edges.filter((edge) => {
        return edge.target !== id && edge.source !== id
      })
    })

    setNodes((nds) => nds.filter((node) => node.id !== id))
  }

  return (
    <div style={{backgroundColor: '#378239', padding: '10px', width: '9vw', display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: '#fff', fontSize: '12px'}}>Fonte de tráfego</span>
        {isOpen ? 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowUpIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div> : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowDownIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <span style={{color: '#fff', marginTop: '10px'}}>Fonte de tráfego</span>
          <select id="traffic" name="traffic" className="nodrag" value={trafficSourceId} onChange={(ev) => setTrafficSourceId(ev.target.value)}>
            <option value={0}>Selecione...</option>
            {traffics.map((traffic, i) => {
              return (
                <option key={i} value={traffic.id}>{traffic.name}</option>
              )
            })}
          </select>
          <span style={{color: '#fff', marginTop: '5px'}}>Descrição</span>
          <input id="description" name="description" className="nodrag" value={trafficSourceDescription} onChange={(ev) => setTrafficSourceDescription(ev.target.value)} />
          <span style={{color: '#fff', marginTop: '5px'}}>Comentário</span>
          <textarea id="comment" name="comment" className="nodrag" value={comment} onChange={(ev) => setComment(ev.target.value)} />
          <button style={{marginTop: '10px'}} onClick={() => onSave()}>Salvar</button>
        </>
      )}
      
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
        isConnectable={isConnectable}
      />
    </div>
  );
};