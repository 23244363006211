import { Box, Button, FormControl, InputLabel, OutlinedInput, Paper} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';


const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const submit = async (ev) => {
    try {
      ev.preventDefault()
      await api.post('/password/reset', { oldPassword, newPassword })

      toast.success('Sua senha foi alterada com sucesso!')
      navigate('/dashboard')
    } catch (error) {
      console.log(error)
      toast.error('Ops, ocorreu algum erro!')
      navigate('/dashboard')
    }
  }


  return (
    <MainCard title="Alterar Senha">
      <Paper>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-oldpassword-login">Senha atual</InputLabel>
          <OutlinedInput
            id="outlined-adornment-oldpassword-login"
            type="password"
            value={oldPassword}
            name="oldPassword"
            onChange={(ev) => setOldPassword(ev.target.value)}
            label="Senha Atual"
          />
        </FormControl>

        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-newpassword-login">Nova Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-newpassword-login"
            type="password"
            value={newPassword}
            name="password"
            onChange={(ev) => setNewPassword(ev.target.value)}
            label="Nova Senha"
          />
        </FormControl>

        <Box sx={{ mt: 2 }}>
          <AnimateButton>
            <Button disableElevation disabled={newPassword === '' || oldPassword === ''} onClick={(ev) => submit(ev)} fullWidth size="large" type="submit" variant="contained" color="secondary">
              Alterar Senha
            </Button>
          </AnimateButton>
        </Box>
      </Paper>
    </MainCard>
  )
};

export default ChangePassword