import axios from "axios";
import { getToken, logout } from "./auth";

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:3333" : "https://api.hubmaze.com"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error)
});

api.interceptors.response.use(response => response, (error) => {
  if (401 === error.response.status || 403 === error.response.status) {
    logout()
    window.location.href = '/login'
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
})

export default api;