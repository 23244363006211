import { Handle, Position, useReactFlow } from 'reactflow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import Delete from '@mui/icons-material/Delete'

export const ScriptNode = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setNodes, setEdges } = useReactFlow();

  const deleteNode = () => {    
    setEdges((edges) => {
      return edges.filter((edge) => {
        return edge.target !== id && edge.source !== id
      })
    })

    setNodes((nds) => nds.filter((node) => node.id !== id))
  }

  return (
    <div style={{backgroundColor: '#000000', padding: '10px', width: '9vw', display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: '#fff', fontSize: '12px'}}>Script</span>
        {isOpen ? 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowUpIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div> : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <KeyboardArrowDownIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
            <Delete style={{color: '#fff', cursor: 'pointer', fontSize: '20px'}} onClick={() => deleteNode()} />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <span style={{color: '#fff', marginTop: '10px'}}>Mensagem</span>
          <input id="loadingmessage" name="loadingmessage" className="nodrag" />
          <span style={{color: '#fff', marginTop: '5px'}}>Script</span>
          <textarea id="comment" name="comment" className="nodrag" />
          <span style={{color: '#fff', marginTop: '5px'}}>Tempo</span>
          <select id="wait" name="wait" className="nodrag" />
        </>
      )}
      
      <Handle
        type="target"
        position={Position.Left}
        id={uuidv4()}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={uuidv4()}
      />
    </div>
  );
};